import {
    ArticleCard,
    DealerPromotion,
    EquipmentsAndAccessories,
    Heading,
    HorizontalDrawer,
    PackageOptionsAndAccessories,
    PaymentOptions,
    PromotionCard,
    StickyBar,
    StickyPaymentOptions,
    SummaryBar,
    TabSlider,
    Trade360Widget,
    VehicleCtas,
    VehicleDescription,
    VehicleSpecsExcerpt,
    VehicleHighlightCards,
    VehicleName,
    VehicleRebates,
    VehicleSpecs,
    VehicleTagline,
} from '@sm360/components'
import { heightCalculator, resetParentsOverflow, WindowProxy, setInnerHTML } from '@sm360/helpers'
import { useDeviceType, useTranslation } from '@sm360/hooks'
import React from 'react'
import Cookies from 'js-cookie'

import { getQuoteDeskingPaymentOptions, getJwt } from '../../api/showroom360/showroom360.service'

import { SummaryContext } from '../../configs/summary-view/state.manager'
import { quoteDeskingTransform } from '../../transforms/quoteDesking.transform'
import { getCalculatorApiUrl } from '../../api/showroom360/showroom360.service'

const SummaryAlpha = () => {
    const baseClass = 's360-p-summaryAlpha'

    const { t } = useTranslation()

    const { device } = useDeviceType()

    const {
        state: {
            dealerPromotion,
            showroomQuote,
            paymentOptions: showroomPaymentOptions,
            showroom360ConfigVdp: { summaryHighlightCardConfigurations, summaryCTAConfigurations },
            vehicleTrim: originalVehicleTrim,
            vehicleTrim: { vehicleTrim },
            calculatorWidget,
            tradeInWidget: { tradeInSidebar, tradeInAfterSimilar },
            dealerInfos,
            dealerInfos: { lang, domain },
            XMSConfig,
            vehiclePromo,
            articleVehicle,
        },
        dispatch,
    } = React.useContext(SummaryContext)

    // TODO: Delete when we have the section in Delta
    const showTrade360 = {
        displayTradeInSidebar: true,
        displayTradeInAfterSimilar: true,
    }

    const [isCalculatorModalOpen, setIsCalculatorModalOpen] = React.useState(false)
    const [forceUpdatePurchaseMethod, setPurchaseMethod] = React.useState(false)

    const { taggingConfigurations } = XMSConfig
    const rebatesData = taggingConfigurations?.find((config) => config.code === 'total_rebates')
    const rebatesDataGovernment = taggingConfigurations?.find((config) => config.code === 'government_rebates')
    // TODO: Add Websites Delta configuration for manufacturer rebates for VDP
    const rebatesDataManufacturer = {
        active: true,
        label: paymentOptions?.bestIncentives?.manufacturerRebatesLabel || t('paymentOptions.incentives.manufacturer'),
        picto: 'icon-rebate',
    }

    const { gallery, vehicle, quoteInfos } = quoteDeskingTransform(showroomQuote, t) || {}

    const { id: quoteId } = quoteInfos || {}

    const {
        make,
        model,
        year,
        modelTrim,
        exteriorColourPhotos,
        interiorTrimPhotos,
        consumption,
        bodyStyle,
        bodyClassification,
        seating,
        doors,
        engine,
        transmission,
        driveTrain,
        tagline,
        description,
        newCarTrimId,
    } = vehicle || {}

    const vehicleInfo = {
        make,
        model,
        modelTrim,
        year,
        newCarTrimId,
        sideImg: exteriorColourPhotos?.side,
    }

    const { accessoriesAndOptions, fuel, disclaimer, paymentOptions, specifications } = vehicleTrim || {}

    const highlights = summaryHighlightCardConfigurations

    React.useEffect(() => {
        if (WindowProxy) {
            WindowProxy.document.addEventListener('sm360.calculator.vdp.modal.close', () => onCalculatorModalOpen(false))
        }
    }, [])

    React.useEffect(() => {
        if (document) {
            resetParentsOverflow('[data-s360-sticky]')
        }
    })

    const onCalculatorModalOpen = (isOpen) => {
        setIsCalculatorModalOpen(isOpen)
        setPurchaseMethod(true)
    }

    const handleCalculatorCta = async (purchaseMethod, term, frequency, kmPerYearPlan) => {
        const calculatorWidget = await getCalculatorApiUrl(
            lang,
            dealerInfos,
            quoteId,
            true,
            true,
            domain,
            purchaseMethod,
            term,
            frequency,
            kmPerYearPlan
        )
        const dataStandalone = document.querySelectorAll('[data-standalone]')
        if (dataStandalone.length > 0) {
            dataStandalone.forEach(async (element) => {
                await setInnerHTML(element, calculatorWidget)
                setPurchaseMethod(false)
                setTimeout(() => setIsCalculatorModalOpen(true), 500)
            })
        }
    }

    const updateVehiclePurchaseMethod = async (paymentOptionsObj) => {
        let updatedVehicleTrim
        if (paymentOptionsObj.purchaseMethod === 'cash') {
            updatedVehicleTrim = {
                ...originalVehicleTrim, // Spread the properties of vehicleTrim
                vehicleTrim: {
                    ...originalVehicleTrim.vehicleTrim, // Spread the properties of the nested vehicleTrim
                    paymentOptions: {
                        ...originalVehicleTrim.vehicleTrim.paymentOptions, // Spread the existing paymentOptions
                        cashPurchase: {
                            ...originalVehicleTrim.vehicleTrim.cashPurchase,
                            ...paymentOptionsObj.cashPurchase,
                        },
                    },
                },
            }
        } else {
            updatedVehicleTrim = {
                ...originalVehicleTrim, // Spread the properties of vehicleTrim
                vehicleTrim: {
                    ...originalVehicleTrim.vehicleTrim, // Spread the properties of the nested vehicleTrim
                    paymentOptions: {
                        ...originalVehicleTrim.vehicleTrim.paymentOptions, // Spread the existing paymentOptions
                        [paymentOptionsObj?.purchaseMethod]: {
                            ...originalVehicleTrim.vehicleTrim.paymentOptions.finance, // Spread existing finance if it exists
                            ...paymentOptionsObj.finance, // Overwrite or add properties from the finance object
                        },
                    },
                },
            }
        }

        dispatch({ type: 'updateVehicleTrim', vehicleTrim: updatedVehicleTrim })
    }

    // TODO: Need to remove this logic once have correct pricing from Showroom backend
    const getShowroomQuote = async (selectedPurchaseMethod) => {
        const cookie = Cookies.get('auth-token')
        const { orgId, orgUnitId, orgUnitProvince, lang } = dealerInfos
        const selectedFinanceOptions =
            selectedPurchaseMethod === 'lease'
                ? showroomPaymentOptions.financeOptions[0]
                : showroomPaymentOptions.financeOptions[1]
        const initJwt = cookie || (await getJwt())
        const paramsPaymentOptions = {
            quoteId: quoteId,
            lang,
            location: orgUnitProvince,
            orgId: parseInt(orgId),
            orgUnitId: parseInt(orgUnitId),
            payload: {
                transactionToken: showroomPaymentOptions?.transactionToken,
                saleType: showroomPaymentOptions?.saleType,
                taxPlan: showroomPaymentOptions?.taxPlan,
                vehicleCondition: showroomPaymentOptions?.vehicleCondition,
                odometer: showroomPaymentOptions?.odometer,
                purchaseMethod: selectedPurchaseMethod,
                financePlan: selectedFinanceOptions.financePlan,
                kmPerYearPlan: selectedFinanceOptions.kmPerYearPlan,
                paymentFrequency: selectedFinanceOptions.paymentFrequency,
                term: selectedFinanceOptions.selectedTerm,
                cashDown: showroomPaymentOptions.cashDown,
                tradeIn: showroomPaymentOptions.tradeIn,
                lien: showroomPaymentOptions.lien,
                financeOptions: showroomPaymentOptions?.financeOptions,
            },
            token: initJwt,
        }

        const initPaymentOptions = await getQuoteDeskingPaymentOptions(paramsPaymentOptions)
        updateVehiclePurchaseMethod(initPaymentOptions)
    }

    const paymentOptionsWithDisclaimer = { ...paymentOptions, disclaimer }
    const dummyStickyCtas = [
        {
            buttonLabel: t('cta.addToGarage'),
            version: 'primary',
            slug: 'add_to_garage',
            id: 'addTogarage',
        },
        {
            buttonLabel: t('cta.reserveNow'),
            version: 'primary',
            slug: 'checkout',
            id: 'reserveNow',
        },
    ]

    return (
        <div className={baseClass}>
            <StickyBar wrapperClass={`${baseClass}__stickyBar ${baseClass}__wrapper`} targetClass={`.${baseClass}`} offset={200}>
                <div className={`${baseClass}__stickyBarInfosLeft ${device !== 'desktop' ? '-responsive' : ''}`}>
                    <VehicleName
                        year={year}
                        makeName={make?.name}
                        modelName={model?.name}
                        trimName={modelTrim?.name}
                        isFullName
                    />

                    <div className={`${baseClass}__stickyBarVehicleRebates`}>
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -dealer`}
                            rebateValue={paymentOptions?.bestIncentives?.dealerRebates}
                            vehicleRebatesData={rebatesData}
                            layout='straight'
                            lang={lang}
                        />
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -government`}
                            rebateValue={paymentOptions?.bestIncentives?.governmentRebates}
                            vehicleRebatesData={rebatesDataGovernment}
                            layout='straight'
                            lang={lang}
                        />
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -manufacturer`}
                            rebateValue={paymentOptions?.bestIncentives?.manufacturerRebates}
                            vehicleRebatesData={rebatesDataManufacturer}
                            layout='straight'
                            lang={lang}
                        />
                    </div>

                    {device === 'desktop' && (
                        <VehicleSpecsExcerpt
                            className={`${baseClass}__stickyBarVehicleSpecs`}
                            exteriorColor={{ colorDescription: exteriorColourPhotos?.description }}
                            interiorColor={{ colorDescription: interiorTrimPhotos?.colourDescription }}
                            fuelName={fuel?.name}
                            fuelType={fuel?.slug}
                            isNewVehicle={true}
                            lang={lang}
                            layout='-line'
                        />
                    )}
                </div>
                {device === 'desktop' && (
                    <div className={`${baseClass}__stickyBarInfosRight`}>
                        <StickyPaymentOptions
                            newVehicle={true}
                            paymentOptions={paymentOptions}
                            isVDPView
                            purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                            showroomQuote={showroomPaymentOptions}
                            lang={lang}
                            handleCalculatorCta={handleCalculatorCta}
                            isCalculatorModalOpen={!!isCalculatorModalOpen}
                            showRetailsTotal={false}
                        />

                        <div className={`${baseClass}__stickyBarVehicleCtas`}>
                            <VehicleCtas
                                vehicle={vehicle}
                                showroomQuote={showroomPaymentOptions}
                                category='inventory'
                                paymentOptions={paymentOptions}
                                dealerInfos={dealerInfos}
                                ctas={dummyStickyCtas}
                            />
                        </div>
                    </div>
                )}
            </StickyBar>

            <div className={`${baseClass}__navigation`}>
                <SummaryBar vehicleInfo={vehicleInfo} />
            </div>

            <div className={`${baseClass}__wrapper`}>
                <div className={`${baseClass}__grid`}>
                    <main className={`${baseClass}__main`}>
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -dealer`}
                            rebateValue={paymentOptions?.bestIncentives?.dealerRebates}
                            vehicleRebatesData={rebatesData}
                            layout='straight'
                            lang={lang}
                        />
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -government`}
                            rebateValue={paymentOptions?.bestIncentives?.governmentRebates}
                            vehicleRebatesData={rebatesDataGovernment}
                            layout='straight'
                            lang={lang}
                        />
                        <VehicleRebates
                            extraClasses={`${baseClass}__vehicleRebate -manufacturer`}
                            rebateValue={paymentOptions?.bestIncentives?.manufacturerRebates}
                            vehicleRebatesData={rebatesDataManufacturer}
                            layout='straight'
                            lang={lang}
                        />

                        {device !== 'desktop' && (
                            <VehicleName
                                year={year}
                                makeName={make?.name}
                                modelName={model?.name}
                                trimName={modelTrim?.name}
                                isFullName
                            />
                        )}

                        <TabSlider tabs={gallery} />

                        {device !== 'desktop' && (
                            <div className='vehicleInfo'>
                                <div className='vehicleInfo__container'>
                                    <VehicleTagline tagline={tagline} extraClasses={`${baseClass}__tagline`} />

                                    <div className='vehicleTabs'>
                                        <PaymentOptions
                                            newVehicle={true}
                                            paymentOptions={paymentOptionsWithDisclaimer}
                                            isVDPView
                                            purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                            showroomQuote={showroomPaymentOptions}
                                            lang={lang}
                                            handleCalculatorCta={handleCalculatorCta}
                                            isCalculatorModalOpen={!!isCalculatorModalOpen}
                                            showRetailsTotal={false}
                                            forceUpdatePurchaseMethod={forceUpdatePurchaseMethod}
                                        />
                                        <section className={`${baseClass}__vehicleSpecsExcerptMobile`}>
                                            <Heading Tag='h2' variant='heading3'>
                                                {t('vehicle.specifications')}
                                            </Heading>

                                            <VehicleSpecsExcerpt
                                                exteriorColor={{ colorDescription: exteriorColourPhotos?.description }}
                                                interiorColor={{ colorDescription: interiorTrimPhotos?.colourDescription }}
                                                fuelName={fuel?.name}
                                                fuelType={fuel?.slug}
                                                isNewVehicle={true}
                                                lang={lang}
                                                layout='-line'
                                            />
                                        </section>

                                        <Heading
                                            extraClasses={`${baseClass}__ctaSteps__header`}
                                            Tag='h4'
                                            variant='heading3'
                                            subTitle={t('vehicle.cta.nextSteps.description')}
                                        >
                                            {t('vehicle.cta.nextSteps.title')}
                                        </Heading>

                                        <VehicleCtas
                                            calculatorWidget={calculatorWidget}
                                            vehicle={vehicle}
                                            showroomQuote={showroomPaymentOptions}
                                            category='catalog'
                                            paymentOptions={paymentOptions}
                                            ctas={summaryCTAConfigurations}
                                            dealerInfos={dealerInfos}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <VehicleSpecs
                            specs={{
                                consumption,
                                frameCategory: bodyClassification?.frameCategory?.frameCategory,
                                bodyStyle: bodyStyle?.name,
                                doors,
                                passengers: seating,
                                engineType: engine?.name,
                                engineDescription: engine?.power,
                                fuel: fuel?.name,
                                transmission: transmission?.name,
                                gears: transmission?.speeds,
                                driveTrain: driveTrain?.name,
                                exteriorColor: exteriorColourPhotos?.description,
                                interiorColor: interiorTrimPhotos?.colourDescription,
                            }}
                            showOdometer={false}
                            extraClasses={`${baseClass}__vehicleSpecs`}
                        />

                        <VehicleDescription description={description} model={model?.name} />

                        <PackageOptionsAndAccessories
                            optionsAndAccessories={accessoriesAndOptions}
                            displayLimit={false}
                            showEmptyElem={false}
                            headingClass='il-heading3'
                            extraClasses={`${baseClass}__vehicleOptions`}
                        >
                            <Heading extraClasses={`${baseClass}__header il-heading3`} Tag={'h2'} variant='heading3'>
                                {t('vehicle.accessoriesAndOptions')}
                            </Heading>
                        </PackageOptionsAndAccessories>

                        <EquipmentsAndAccessories
                            vehicleTrim={specifications}
                            lang={lang}
                            extraClasses={`${baseClass}__equipmentsAndAccessories`}
                        >
                            <Heading extraClasses={`${baseClass}__header il-heading3`} Tag={'h2'} variant='heading3'>
                                {t('vehicle.equipmentsAndAccessories')}
                            </Heading>
                        </EquipmentsAndAccessories>

                        {highlights?.length > 0 && (
                            <VehicleHighlightCards vehicleInventory={vehicle} highlights={highlights} quoteId={quoteId} />
                        )}

                        <DealerPromotion promotion={dealerPromotion?.promotions[0]} />

                        {showTrade360.displayTradeInAfterSimilar && (
                            <Trade360Widget
                                extraClasses={`${baseClass}__trade360`}
                                widgetId={'tradeInAfterSimilar'}
                                tradeInWidget={tradeInAfterSimilar}
                                elementNodeName={'data-trade-standalone'}
                                elementNodeValue={'tradeInAfterSimilar'}
                            />
                        )}
                    </main>

                    {device === 'desktop' && (
                        <aside className={`${baseClass}__aside`}>
                            <section style={{ top: heightCalculator('[data-sr360-header]') }} data-s360-sticky>
                                <div className='vehicleInfo'>
                                    <div className='vehicleInfo__container'>
                                        <VehicleName
                                            year={year}
                                            makeName={make?.name}
                                            modelName={model?.name}
                                            trimName={modelTrim?.name}
                                            isFullName
                                            extraClasses={`${baseClass}__vehicleName`}
                                        />

                                        <VehicleTagline tagline={tagline} extraClasses={`${baseClass}__tagline`} />

                                        <div className='vehicleTabs'>
                                            <PaymentOptions
                                                newVehicle={true}
                                                paymentOptions={paymentOptionsWithDisclaimer}
                                                isVDPView
                                                isDetailView
                                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                                showroomQuote={showroomPaymentOptions}
                                                lang={lang}
                                                handleCalculatorCta={handleCalculatorCta}
                                                isCalculatorModalOpen={!!isCalculatorModalOpen}
                                                showRetailsTotal={false}
                                                forceUpdatePurchaseMethod={forceUpdatePurchaseMethod}
                                                getShowroomQuote={getShowroomQuote}
                                            />

                                            <Heading
                                                extraClasses={`${baseClass}__ctaSteps__header`}
                                                Tag='h4'
                                                variant='heading3'
                                                subTitle={t('vehicle.cta.nextSteps.description')}
                                            >
                                                {t('vehicle.cta.nextSteps.title')}
                                            </Heading>

                                            <VehicleCtas
                                                calculatorWidget={calculatorWidget}
                                                vehicle={vehicle}
                                                showroomQuote={showroomPaymentOptions}
                                                category='catalog'
                                                paymentOptions={paymentOptions}
                                                ctas={summaryCTAConfigurations}
                                                dealerInfos={dealerInfos}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {showTrade360.displayTradeInSidebar && (
                                <Trade360Widget
                                    extraClasses={`${baseClass}__trade360`}
                                    widgetId={'tradeInSidebar'}
                                    tradeInWidget={tradeInSidebar}
                                    elementNodeName={'data-trade-standalone'}
                                    elementNodeValue={'tradeInSidebar'}
                                />
                            )}
                            {(vehiclePromo?.promotions.length > 0 || articleVehicle.length > 0) && (
                                <section className={`${baseClass}__promotionArticle`}>
                                    {vehiclePromo?.promotions.length > 0 && (
                                        <PromotionCard promotion={vehiclePromo.promotions[0]} />
                                    )}

                                    {articleVehicle.length > 0 && <ArticleCard article={articleVehicle[0]} />}
                                </section>
                            )}{' '}
                        </aside>
                    )}
                </div>
            </div>

            {device !== 'desktop' && (
                <HorizontalDrawer
                    SmallContent={() => (
                        <>
                            <StickyPaymentOptions
                                newVehicle={true}
                                paymentOptions={paymentOptions}
                                isVDPView
                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                showroomQuote={showroomPaymentOptions}
                                lang={lang}
                                handleCalculatorCta={handleCalculatorCta}
                                isCalculatorModalOpen={!!isCalculatorModalOpen}
                                extraClasses={`${baseClass}__mobileFooterPaymentOptions`}
                            />
                            <VehicleCtas
                                vehicle={vehicle}
                                showroomQuote={showroomPaymentOptions}
                                category='inventory'
                                paymentOptions={paymentOptions}
                                dealerInfos={dealerInfos}
                                ctas={dummyStickyCtas}
                                extraClasses={`${baseClass}__mobileFooterCtas`}
                            />
                        </>
                    )}
                    handleCalculatorCta={handleCalculatorCta} // TODO: Remove handleCalculatorCta props to open calculator once we have Drawer content
                >
                    <p>Big Content (future payment calculator)</p>
                </HorizontalDrawer>
            )}
            <div className={`${baseClass}__calculator`} data-standalone='paymentCalculator'></div>
        </div>
    )
}

export default SummaryAlpha
